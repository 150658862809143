:root {
  --org: rgb(202, 100, 16);
  --blue: #094D84;
  --white: #ffffff;
  --gray-l: rgb(105, 103, 103);
  --gray-d: rgb(59, 59, 59);
}

@font-face {
  font-family: 'digital-7';
  src: url('../src/assets/text/digital-font-family/Digital-7Mono.eot'); /* IE9 Compat Modes */
  src: url('../src/assets/text/digital-font-family/Digital-7Mono.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
      url('../src/assets/text/digital-font-family/Digital-7Mono.woff2') format('woff2'), /* Super Modern Browsers */
      url('../src/assets/text/digital-font-family/Digital-7Mono.woff') format('woff'), /* Pretty Modern Browsers */
      url('../src/assets/text/digital-font-family/Digital-7Mono.ttf')  format('truetype'), /* Safari, Android, iOS */
      url('../src/assets/text/digital-font-family/Digital-7Mono.svg') format('svg'); /* Legacy iOS */
      font-style: normal;
      font-display: swap;
    }

@font-face {
   font-family: 'Norwester';
   src: url('../src/assets/text/norwester-regular/Norwester-Regular.eot');
   src: url('../src/assets/text/norwester-regular/Norwester-Regular.eot?#iefix') format('embedded-opentype'),
         url('../src/assets/text/norwester-regular/Norwester-Regular.woff2') format('woff2'),
         url('../src/assets/text/norwester-regular/Norwester-Regular.woff') format('woff'),
         url('../src/assets/text/norwester-regular/Norwester-Regular.ttf') format('truetype'),
         url('../src/assets/text/norwester-regular/Norwester-Regular.svg') format('svg');
   font-weight: normal;
   font-style: normal;
   font-display: swap;
  }

body {
font-family: 'Roboto', sans-serif;
color: white;
}

body {
  border: none;
  width: 100%;
  height: 100%;
  background-image: inherit;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-color: var(--gray-d);
  background-image: url("/src/assets/img/portfolio-page-background.png");
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}