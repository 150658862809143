.header-background {
   border: none;
   width: 100%;
   // height: 48vh;
   /* background-image: inherit; */
   // background-attachment: fixed;
   background-repeat: no-repeat;
   background-position: center;
   background-size: cover;
   background-color: $gray;
   background-image: url("/src/assets/img//homepage/1.png");
}
.header-title{
   font-family: Norwester;
   text-align: center;
   font-size: 8.2vw;
   padding: 5vh;

   span {
      margin: 1.5vw;
   }
}

.header-paragraph {
   text-align: center;
   font-size: 3.7vw;
}

@media screen and (max-width: 856px) {

   .header-background {
      min-height: 55vw;
   }
   .header-title{
      font-size: 10vw;
   }

  .header-paragraph {
      font-size: 4vw;
   }
}