/* home pg */
.home-top-banner {
   border: none;
   width: 100%;
   min-height: 70vh;
   position: relative;
   /* background-image: inherit; */
   // background-attachment: fixed;
   background-repeat: no-repeat;
   background-position: bottom;
   background-size: cover;
   background-color: $gray;
   background-image: url("/src/assets/img/homepage/2.png");
}

.cont-profile-pic {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 0vw;
  padding-top: 5vw;
}

.profile-pic {
  width: 25vw;
  border-radius: 50%;
  box-shadow: 5px 5px 10px rgb(37, 37, 37);
}

.about-text-home {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  justify-items: center;
  text-align: center;
  margin: 1vw 1vw 0vw 1vw;
  padding: 2vw;
  font-size: 2.5vw;
}

.home-portfolio-btn{
   font-family: Norwester;
   background-color: rgba(255, 255, 255, 0.15);
   color: white;
   border: 3px solid $orange;
   border-radius: 1rem;
   padding: 1.3vw;
   a {
      text-decoration: none;
      color: white;
      font-size: 5vw;
      // text-shadow: 0px 0px 8px rgb(114, 111, 111), 0px 0px 25px rgb(114, 111, 111);
  }
}
@media (hover: hover) {
   .home-portfolio-btn:hover {
      // background-color: transparent;
      // color: $orange;
      border: 3px solid white;

      color: rgba(255, 255, 255, 1);
      box-shadow: 1px 1px 20px $orange;
      a {
         color: $orange
      }
   }
}

.dividers-line {
  border-top: 5px solid white;
}

.icon-header {
  margin: 28px 5px 8px 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 4vw;
}

.data-text-header-home,
.developer-text-header-home,
.designer-text-header-home {
  color: $orange;
  font-family: Norwester;
  font-size: 8vw;
  text-align: center;
  margin: 5vw 0 0vw 0;
}

.data-logos-container {
   font-family: Norwester;
   display: grid;
   grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
   justify-items: center;
   align-items: center;
   justify-content: center;
   margin-bottom: 2vw;
 }

.code-logos-container,
.design-logos-container {
  font-family: Norwester;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  justify-items: center;
  align-items: center;
  justify-content: center;
  margin-bottom: 2vw;
}

.code-logos {
  justify-items: center;
  align-items: center;
  text-align: center;
  font-size: 2.5vw;

  img {
    max-width: 100%;
    height: auto;
    min-width: 30px;
  }
  h3 {
   margin-top: -2.5vw;
 }
}

.email-home {
   font-family: Norwester;
   color: $orange;
   margin: 1vw 0 0 0 ;
   display: flex;
   justify-content: center;
   align-items: center;
   align-content: center;
   justify-items: center;
   text-align: center;
   font-size: 5vw;
 }

.email {
  font-family: Norwester;
  margin: 1vw 0 0 0 ;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  justify-items: center;
  text-align: center;
  font-size: 5vw;
}

@media screen and (max-width: 856px) {
   .home-top-banner {
      background-position: bottom;
      // height: 99vh;
   }
  .cont-profile-pic {
    margin-top: 0;
    padding-top: 20vw;
  }
  .profile-pic {
    width: 60vw;
  }
  .about-text-home {
    padding: 0 2vw 5vw 2vw;
    font-size: 5vw;
    margin: 3vw 1vw 0vw 1vw;
  }
  .home-portfolio-btn {
      border: 3px solid $orange;
      border-radius: .5rem;
      padding: 3vw;
      // transition: all 0.2s ease-in-out;
      a {
         font-size: 9vw;
      }
   }
   .data-text-header-home,
   .developer-text-header-home,
   .designer-text-header-home {
      color: $orange;
      font-family: Norwester;
      font-size: 15vw;
      text-align: center;
      margin: 5vw 0 0vw 0;
   }
  .code-logos {
    font-size: 6.5vw;
  
    img {
      height: auto;
      width: 30vw;
    }
    h3 {
      margin-top: -3.5vw;
      font-size: 6vw;
    }
  }
  .icon-header {
    padding: 0 1vw 0 1vw;
    font-size: 6.5vw;
  }
  .code-logos-container,
  .design-logos-container {
    grid-template-columns: 1fr 1fr; 
  }
  .gsap {
     display: none;
  }
  .email-home {
   margin: 1vw 0 0 0 ;
   font-size: 6vw;
 }
  .email {
    font-size: 6vw;
  }

}