.contact-text-container {
    color: white;
}

.contact-text-header {
    color: white;
    font-family: Norwester;
    font-size: 9vw;
    text-align: center;
    margin: 5vw 0 4vw 0;
}

.contact-text-paragraph {
    color: white;
    font-size: 5vw;
    text-align: center;

}

@media screen and (max-width: 856px) {
    
    .contact-text-header {
        font-size: 15vw;
        margin: 18vw 0px 4vw 0;
    }
    
    .contact-text-paragraph {
        font-size: 10vw;
    }
}