.datacard-info {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    text-align: center;
    background-color: $trans1;
    font-family: Norwester;
    border-radius: 25px;
    color: white;
    margin: 1vw;
    padding: 1vw;
    width: 100%;
    height: 55vw;

    h1 {
      margin-top: 1vw;
      font-size: 2.5vw;
      height: 9vw;
      text-align: center;
    }
    p {
      // font-family: 'Roboto';
      // text-align: justify;
      margin-top: 2vw;
      font-size: 1.5vw;
    }
    a {
      text-decoration: none;
      color: white;
      
    }
  }
  
  .datacard-pic {
    width: 95%;
   //  max-height: 30vw;
    border: 3px solid $orange;
    border-radius: 1em;
    justify-content: center;
    align-items: center;
   //  margin: 2vw 2vw 2vw 2vw;
  }

  @media screen and (max-width: 856px) {
   .datacard-info {
      border-radius: 25px;
      margin: 2vw;
      padding: 4vw;
      width: 85%;
      height: 100%;
        
        h1 {
         margin-top: 10px;
         font-size: 6.5vw;
         height: auto;
       }
       p {
        margin-top: 10px;
        font-size: 3.5vw;
      }
      }
     
    }