.project-title{
   font-size: 3.5vw;
   text-align: center;
}

.project-pic-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 5vw 0 0 0;
}

.data1-pic {
  width: 95vw;
  box-shadow: 5px 5px 10px rgb(37, 37, 37);
}

.project-disclaimer {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  justify-items: center;
  align-items: center;
  align-content: center;
  text-align: center;
  margin: 2.5vw 0 0 0;
  padding: 0 2vw 0 2vw;
  font-size: 1.5vw;
}

.project-description {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  justify-items: center;
  align-items: center;
  align-content: center;
  text-align: justify;
  margin: 2.5vw 3vw 0 3vw;
  padding: 0 1vw 0 1vw;
  font-size: 3vw;
}

.project-description-list {
   display: flex;
   flex-direction: column;
   flex-wrap: nowrap;
   justify-content: center;
   justify-items: center;
   align-items: flex-start;
   align-content: center;
   text-align: justify;
   margin: 2.5vw 3vw 0 6vw;
   padding: 0 1vw 0 1vw;
   font-size: 3vw;
 }

.github-icon-container{
   display: flex;
   flex-direction: column;
   align-content: center;
   justify-content: center;
   align-items: center;
}

.github-large-icon {
   text-decoration: none;
   margin: 5vw 0 3vw 0;

   a{
      text-decoration: none;
      color: white;
      font-size: 10vw;
      display: flex;
      flex-direction: column;
      align-items: center;
      align-content: center;
      justify-content: center;
   }
   
   h4{
      text-decoration: none;
      text-align: center;
      margin: 5px;
      font-size: 5vw;
   }
}

.dev-icon-container {
   display: flex;
   flex-direction: row;
 }
 

.back-portfolio-btn{
   font-family: Norwester;
   background-color: rgba(255, 255, 255, 0.15);
   color: white;
   border: 3px solid $orange;
   border-radius: 1rem;
   margin: 4vw 0 0 0;
   padding: 1.3vw;
   a {
      text-decoration: none;
      color: white;
      font-size: 5vw;
      // text-shadow: 0px 0px 8px rgb(114, 111, 111), 0px 0px 25px rgb(114, 111, 111);
  }
}
  
// .email {
//   font-family: digital-7;
//   margin-top: 20px;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   align-content: center;
//   justify-items: center;
//   text-align: center;
//   font-size: 45px;
// }


@media screen and (max-width: 856px) {
  
   .project-title{
      font-size: 4.5vw;
   }
   
   .project-pic-container {
     margin-top: 5vw;
   }
   
   .data1-pic {
     width: 95vw;
     box-shadow: 5px 5px 10px rgb(37, 37, 37);
   }
   
   .project-disclaimer {
     margin: 2vw 0 0 0;
     padding: 0 1vw 0 1vw;
     font-size: 3.5vw;
   }
   
   .project-description {
     margin: 2.5vw 2vw 0 2vw;
     padding: 0 2vw 0 2vw;
     font-size: 4vw;
   }

   .project-description-list {
     margin: 2.5vw 2vw 0 6vw;
     padding: 0 2vw 0 2vw;
     font-size: 4vw;
   }

   .github-large-icon {
      margin: 5vw 1vw 3vw 1vw;
      a{
         font-size: 15vw;
      }
      h4{
         margin: 5px;
         font-size: 5vw;
      }
   }

   .back-portfolio-btn {
      margin: 6vw 0 0 0;
      border: 3px solid $orange;
      border-radius: .5rem;
      padding: 3vw;
      a {
         font-size: 6vw;
      }
   }

}