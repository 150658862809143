
/* about pg */

// .cont-profile-pic {
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   margin-top: 60px;
// }

// .profile-pic {
//   width: 215px;
//   border-radius: 50%;
//   box-shadow: 5px 5px 10px rgb(37, 37, 37);
// }

.about-text {
  display: flex;
  justify-content: center;
  justify-items: center;
  align-items: center;
  align-content: center;
  text-align: center;
  margin: 2.5vw 0 0 0;
  padding: 0 1vw 0 1vw;
  font-size: 3vw;
}
  
// .email {
//   font-family: digital-7;
//   margin-top: 20px;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   align-content: center;
//   justify-items: center;
//   text-align: center;
//   font-size: 45px;
// }

.resume {
  background-color: $orange;
  color: white;
  font-family: digital-7;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2vw 0 0 0;
  font-size: 4.5vw;
}

.secret-message {
  font-family: digital-7;
  display: flex;
  color: white;
  text-align: center;
  text-decoration: none;
  justify-content: center;
  align-items: center;
  font-size: 2.5vw;
  margin: 1vw 0 .5vw 0;
  padding: 0 1vw 0 1vw;
}

@media screen and (max-width: 856px) {
  /* about pg */
  .about-text {
    font-size: 6.5vw;
    padding: 0 2vw 0 2vw;
  }

  .resume {
    font-size: 9.5vw;
  }

  .secret-message {
    font-size: 5.5vw;
    margin: 1vw 0 .5vw 0;
    padding: 0 1vw 0 1vw;
  }

}