.dev-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: $trans1;
    font-family: Norwester;
    border-radius: 25px;
    color: white;
    margin: 10px;
    padding: 10px;
    width: 100%;
  }
  
  
  .dev-info h1 {
    margin-top: 10px;
    font-size: 2.5vw;
    text-align: center;
  }
  .dev-info p {
   margin-top: 10px;
   font-size: 1.5vw;
   text-align: center;
 }
  
  .dev-info a {
    text-decoration: none;
    color: white;
    flex-direction: row;
  }
  
  .dev-info i {
    font-size: 2vw;
    margin-top: 10px;
    flex-direction: row;
    
  }
  
  .dev-icon-container {
    display: flex;
    flex-direction: row;
  }
  
  .dev-icon {
  display: flex;
  flex-direction: row;
  margin: 5px;
  }
  
  .dev-icon h4 {
    margin: 5px;
  }
  
  .dev-pic {
    max-width: 100%;
    max-height: 30vh;
    border: 3px solid $orange;
    border-radius: 1em;
    justify-content: center;
    align-items: center;
    margin: 10px 0 10px 0;
  }

  @media screen and (max-width: 856px) {
    .dev-info {
        /* flex-direction: column; */
        overflow: hidden;
        width: 85%;
        height: 85%;
        
      }
      .dev-info h1 {
         margin-top: 10px;
         font-size: 6.5vw;
       }
       .dev-info p {
        margin-top: 10px;
        font-size: 3.5vw;
      }
       .dev-info i {
         font-size: 6vw;
         margin-top: 10px;
       }
    }