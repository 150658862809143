/* Footer */
footer {
  overflow: hidden;
  font-family: digital-7;
  background-color: $orange;
  border-top: 5px solid white;
  box-shadow: 0px 10px 10px rgba(0,0,0,0.1);
  padding: 2.5vw;
  margin-top: 2.5vw;
}

#footer-relative {
  position: relative;
  bottom:0;
}

#footer-bottom {
  position: fixed;
  margin-top: 90%;
  bottom:0;
}

/* Social media icon links*/

.foot-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;

  .icon-button{
    display: inline-block;
    height: 6vw;
    width: 6vw;
    margin: 0 1vw 0 1vw;
    overflow: hidden;
    background: $graydark;
    border-radius: 4vw;
    cursor: pointer;
    box-shadow: 0px 10px 10px rgba(0,0,0,0.1);
    transition: all 0.3s ease-out;
  }
}

// .foot-wrapper .icon-button:hover{
//   width: 20vw;
// }
.foot-wrapper .icon-button .icon{
  display: inline-block;
  color: white;
  height: 6vw;
  width: 6vw;
  text-align: center;
  border-radius: 50%;
  box-sizing: border-box;
  line-height: 70px;
  transition: all 0.3s ease-out;
}

// .foot-wrapper .icon-button:nth-child(1):hover .icon{
//   background: $trans1;
// }
// .foot-wrapper .icon-button:nth-child(2):hover .icon{
//   background: $trans1;
// }
// .foot-wrapper .icon-button:nth-child(3):hover .icon{
//   background: $trans1;
// }
// .foot-wrapper .icon-button:nth-child(4):hover .icon{
//    background: $trans1;
//  }

.foot-wrapper .icon-button .icon i{
  font-size: 3.5vw;
  line-height: 6vw;
  transition: all 0.3s ease-out;
}
// .foot-wrapper .icon-button:hover .icon i{
//   color: #fff;
// }
.foot-wrapper .icon-button span{
  font-size: 2.5vw;
  line-height: 70px;
  margin: 0 1vw 0 1vw;
  transition: all 0.3s ease-out;
}

.icon-span {
  color: white;
}

.icon-anchor {
  color: white;
  text-decoration: none;
}

.tableau-icon {
   font-size: 3.5vw;
   line-height: 6vw;
   width: 3.5vw;
   margin: 1vw auto 0 auto;
   color: #fff;
   transition: all 0.3s ease-out;
}

@media (hover: hover) {

   .foot-wrapper .icon-button:hover{
      width: 20vw;
    }
   .foot-wrapper .icon-button:nth-child(1):hover .icon{
      background: $trans1;
    }
    .foot-wrapper .icon-button:nth-child(2):hover .icon{
      background: $trans1;
    }
    .foot-wrapper .icon-button:nth-child(3):hover .icon{
      background: $trans1;
    }
    .foot-wrapper .icon-button:nth-child(4):hover .icon{
       background: $trans1;
     }
     .foot-wrapper .icon-button:hover .icon i{
      color: #fff;
    }
}

@media screen and (max-width: 856px) {
  .foot-wrapper {
    
    .icon-button{
      height: 15vw;
      width: 15vw;
      border-radius: 50%;
      margin: 0 1vw 0 1vw;

      .icon{
        height: 15vw;
        width: 15vw;
        line-height: 15vw;
        transition: none;

        i{
          font-size: 9vw;
          line-height: 15vw;
        }
      }
  
      span{
        display: none;
        transition: none;
      }
    }
    
  }

  .tableau-icon {
      height: 9vw;
      width: 9vw;
      margin: 2vw auto 0 auto;
      line-height: 15vw;
      transition: none;
   }


//   .foot-wrapper .icon-button:hover{
//     display: contents;
//   }
//   .foot-wrapper .icon-button:nth-child(1):hover .icon{
//     background: $blue;
//   }
//   .foot-wrapper .icon-button:nth-child(2):hover .icon{
//     background: $blue;
//   }
//   .foot-wrapper .icon-button:nth-child(3):hover .icon{
//     background: $blue;
//   }
}