$orange: #CF5609;
$blue: #094D84;;
$white: #ffffff;
$gray: rgb(105, 103, 103);
$graydark: rgb(59, 59, 59);

$trans1: rgba(12, 12, 12, 0.404);
$trans2: rgba(255, 255, 255, 0.15);

$background: #c4c4c420;
$boxshadow: 0 7.6vh 10.6vh -3.0vh rgba(0, 0, 0, 0.85);
