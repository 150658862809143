.contact-form-container{
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    min-height: 70vh;
}

.contact-form {
    max-width: 85vw;
    min-height: 50vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
   //  background-image: linear-gradient($blue, $orange);
   background-color: rgba(12, 12, 12, 0.404);
    border: 3px solid white;
    font-family: Norwester;
    border-radius: 25px;
    color: white;
    margin: 2vw 0 2vw 0;
    padding: 2vw 0 2vw 0;
    width: 100%;
}

label {
    color: white;
    font-size: 2vw;
    margin: .6vw 0vw .2vw 5vw;
}

.contact-form-name,
.contact-form-email,
.contact-form-subject,
.contact-form-message  {
    background-color: rgba(255, 255, 255, 0.15);
    border: 3px solid $orange;
    border-radius: 1rem;
    color: white;
    font-size: 1.5vw;
    margin: 0.5vw auto 1vw auto;
    padding: 1vw 1.3vw 1vw 1.3vw;
    min-width: 85%;
}

.contact-form-message {
   min-height: 20vw;
}

.contact-form-name:hover,
.contact-form-name:focus,
.contact-form-email:hover,
.contact-form-email:focus,
.contact-form-subject:hover,
.contact-form-subject:focus,
.contact-form-message:hover,
.contact-form-message:focus {
    color: white;
    border: 3px solid white;
    background-color: transparent;
}

.contact-form-submit{
    font-family: Norwester;
    background-color: rgba(255, 255, 255, 0.15);
    color: white;
    border: 3px solid $orange;
    border-radius: 1rem;
    font-size: 2.5vw;
    padding: 1.3vw;
}

.contact-form-submit:hover,
.contact-form-submit:focus {
    background-color: transparent;
    color: white;
    border: 3px solid white;
}

#contact-form-send{
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   margin: 5vw auto 1vw auto;
}

.contact-form-result {
   margin: auto;
}

@media screen and (max-width: 856px) {
    .contact-form-container{ 
        min-height: 52vh;
    }

    .contact-form {
      height: 106vw;
      max-width: 90vw;
      border-radius: 25px;
      margin: 5.5vw 0 5.5vw 0;
      padding: 5vw 0 5vw 0;
      width: 100%;
    }
    
    label {
        color: white;
        font-size: 8vw;
        margin: .6vw 0vw .2vw 5vw;
    }
    
    .contact-form-name,
    .contact-form-email,
    .contact-form-subject,
    .contact-form-message  {
        background-color: rgba(255, 255, 255, 0.15);
        border: 3px solid $orange;
        border-radius: 1rem;
        color: white;
        font-size: 6.5vw;
        margin: 0.5vw auto 1vw auto;
        padding: 1vw 1.3vw 1vw 1.3vw;
        min-width: 85%;
        max-width: 94%;
    }

    .contact-form-message {
      min-height: 30vw;
   }

    #contact-form-send{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin: 5vw auto 2vw auto;
   }

    .contact-form-submit{
        font-family: Norwester;
      //   background-color: transparent;
      //   color: white;
      //   border: 3px solid $orange;
        border-radius: 1rem;
        font-size: 9.5vw;
        padding: 1.5vw;
        margin: 1.5vw;
    }
}