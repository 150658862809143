      /* CSS grid */
  .gd-gallery {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      /* grid-template-columns: 1fr 1fr 1fr 1fr; */
      /* grid-template-columns: minmax(400px, 1fr); */
      /* grid-template-columns: repeat(auto-fit, minmax(400px, 1fr)); */
      /* grid-template-rows: repeat(5, 400px); */
      grid-gap: 15px;
      overflow: hidden;
      // width: 100%;
      justify-content: center;
      justify-items: center;
      align-items: center;
      object-fit: cover;
      margin: 2vw 1vw 0 1vw;
      
  }

  .gd-gallery-img {
    max-width: 100%;
    height: auto;
    min-width: 30px;
}
  
  
  @media screen and (max-width: 856px) {
   
    .gd-gallery {
        grid-template-columns: minmax(300px, 1fr);
        margin: 8vw;
    }
  
  }