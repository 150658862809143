.center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-content: center;
}

.container,
.container-sm,
.container-xs {
  width: 100%;
  margin: 0 auto;
  padding-left: 16px;
  padding-right: 16px;
}

.container {
  max-width: 1128px;
}

.container-sm {
  max-width: 944px;
}

.container-xs {
  max-width: 668px;
}

[class*=container] [class*=container] {
  padding-left: 0;
  padding-right: 0;
}
[class*=container] .container-sm {
  max-width: 896px;
}
[class*=container] .container-xs {
  max-width: 620px;
}

body {
  font-family: "Source Sans Pro", Helvetica, Verdana, sans-serif;
}

.center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-content: center;
}

.header-background {
  border: none;
  width: 100%;
  /* background-image: inherit; */
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-color: rgb(105, 103, 103);
  background-image: url("/src/assets/img//homepage/1.png");
}

.header-title {
  font-family: Norwester;
  text-align: center;
  font-size: 8.2vw;
  padding: 5vh;
}
.header-title span {
  margin: 1.5vw;
}

.header-paragraph {
  text-align: center;
  font-size: 3.7vw;
}

@media screen and (max-width: 856px) {
  .header-background {
    min-height: 55vw;
  }
  .header-title {
    font-size: 10vw;
  }
  .header-paragraph {
    font-size: 4vw;
  }
}
/* Footer */
footer {
  overflow: hidden;
  font-family: digital-7;
  background-color: #CF5609;
  border-top: 5px solid white;
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.1);
  padding: 2.5vw;
  margin-top: 2.5vw;
}

#footer-relative {
  position: relative;
  bottom: 0;
}

#footer-bottom {
  position: fixed;
  margin-top: 90%;
  bottom: 0;
}

/* Social media icon links*/
.foot-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}
.foot-wrapper .icon-button {
  display: inline-block;
  height: 6vw;
  width: 6vw;
  margin: 0 1vw 0 1vw;
  overflow: hidden;
  background: rgb(59, 59, 59);
  border-radius: 4vw;
  cursor: pointer;
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease-out;
}

.foot-wrapper .icon-button .icon {
  display: inline-block;
  color: white;
  height: 6vw;
  width: 6vw;
  text-align: center;
  border-radius: 50%;
  box-sizing: border-box;
  line-height: 70px;
  transition: all 0.3s ease-out;
}

.foot-wrapper .icon-button .icon i {
  font-size: 3.5vw;
  line-height: 6vw;
  transition: all 0.3s ease-out;
}

.foot-wrapper .icon-button span {
  font-size: 2.5vw;
  line-height: 70px;
  margin: 0 1vw 0 1vw;
  transition: all 0.3s ease-out;
}

.icon-span {
  color: white;
}

.icon-anchor {
  color: white;
  text-decoration: none;
}

.tableau-icon {
  font-size: 3.5vw;
  line-height: 6vw;
  width: 3.5vw;
  margin: 1vw auto 0 auto;
  color: #fff;
  transition: all 0.3s ease-out;
}

@media (hover: hover) {
  .foot-wrapper .icon-button:hover {
    width: 20vw;
  }
  .foot-wrapper .icon-button:nth-child(1):hover .icon {
    background: rgba(12, 12, 12, 0.404);
  }
  .foot-wrapper .icon-button:nth-child(2):hover .icon {
    background: rgba(12, 12, 12, 0.404);
  }
  .foot-wrapper .icon-button:nth-child(3):hover .icon {
    background: rgba(12, 12, 12, 0.404);
  }
  .foot-wrapper .icon-button:nth-child(4):hover .icon {
    background: rgba(12, 12, 12, 0.404);
  }
  .foot-wrapper .icon-button:hover .icon i {
    color: #fff;
  }
}
@media screen and (max-width: 856px) {
  .foot-wrapper .icon-button {
    height: 15vw;
    width: 15vw;
    border-radius: 50%;
    margin: 0 1vw 0 1vw;
  }
  .foot-wrapper .icon-button .icon {
    height: 15vw;
    width: 15vw;
    line-height: 15vw;
    transition: none;
  }
  .foot-wrapper .icon-button .icon i {
    font-size: 9vw;
    line-height: 15vw;
  }
  .foot-wrapper .icon-button span {
    display: none;
    transition: none;
  }
  .tableau-icon {
    height: 9vw;
    width: 9vw;
    margin: 2vw auto 0 auto;
    line-height: 15vw;
    transition: none;
  }
}
/* navbar */
.desktop-navigation {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  align-content: center;
  z-index: 1;
  padding: 2px;
  height: 5vw;
  border-bottom: solid #CF5609;
  border-width: 0.5vw;
  background-color: rgba(12, 12, 12, 0.404);
}

.links {
  font-family: Norwester;
  float: right;
  padding: 0;
  margin: 0;
  width: 80%;
  height: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.links li {
  list-style: none;
}
.links a {
  display: inline-block;
  margin: 0 2px 0 2px;
  font-size: 2.2vw;
  font-weight: bold;
  color: #fff;
  text-decoration: none;
}

#active {
  color: #CF5609;
}

.mobile-links {
  display: none;
}

.icon-burger {
  display: none;
  position: absolute;
  right: 5%;
  top: 50%;
  transform: translateY(-50%);
}

.icon-burger .line {
  width: 35px;
  height: 5px;
  background-color: #fff;
  margin: 3.5px;
  border-radius: 3px;
  transition: all 0.3s ease-in-out;
}

@media screen and (max-width: 856px) {
  /* navbar */
  .desktop-navigation {
    display: none;
  }
  .icon-burger {
    display: flex;
    z-index: 2;
    position: relative;
    margin-top: 13vw;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
    flex-wrap: nowrap;
    align-content: center;
  }
  .icon-burger .line {
    background-color: #CF5609;
  }
  .mobile-links {
    display: flex;
    font-family: Norwester;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    position: fixed;
    z-index: 1;
    left: 0;
    right: 0;
    top: 2.5vh;
    bottom: 0;
    width: auto;
    height: auto;
    background-color: rgba(0, 0, 0, 0.8);
    overflow: hidden;
    box-sizing: border-box;
    padding: 0;
    transition: all 0.5s ease-in-out;
  }
  .mobile-links li {
    list-style: none;
  }
  .mobile-links a {
    text-decoration: none;
    color: white;
    font-size: 7vw;
  }
}
/* navbar */
.desktop-navigation-home {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  align-content: center;
  z-index: 1;
  bottom: 0;
  padding: 2px;
  margin-top: 25vh;
  height: 3vw;
  border-bottom: solid #CF5609;
  border-width: 0.5vw;
}

.links-home {
  font-family: Norwester;
  float: right;
  padding: 0;
  margin: 0;
  width: 80%;
  height: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.links-home li {
  list-style: none;
}
.links-home a {
  display: inline-block;
  margin: 0 2px 0 2px;
  font-size: 2.2vw;
  font-weight: bold;
  color: #fff;
  text-decoration: none;
}

#active {
  color: #CF5609;
}

.mobile-links-home {
  display: none;
}

.icon-burger-home {
  display: none;
  position: absolute;
  right: 5%;
  top: 50%;
  transform: translateY(-50%);
}

.icon-burger-home .line {
  width: 30px;
  height: 5px;
  background-color: #fff;
  margin: 5px;
  border-radius: 3px;
  transition: all 0.3s ease-in-out;
}

@media screen and (max-width: 856px) {
  /* navbar */
  .desktop-navigation-home {
    display: none;
  }
  .icon-burger-home {
    display: block;
    z-index: 2;
    position: relative;
    float: right;
    height: 0vh;
  }
  .icon-burger-home .line {
    background-color: #CF5609;
  }
  .mobile-links-home {
    display: flex;
    font-family: Norwester;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    position: fixed;
    z-index: 1;
    left: 0;
    right: 0;
    top: 2.5vw;
    bottom: 0;
    width: auto;
    height: auto;
    background-color: rgba(0, 0, 0, 0.8);
    overflow: hidden;
    box-sizing: border-box;
    padding: 0;
    transition: all 0.5s ease-in-out;
  }
  .mobile-links-home li {
    list-style: none;
  }
  .mobile-links-home a {
    text-decoration: none;
    color: white;
    font-size: 7vw;
  }
}
.dev-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: rgba(12, 12, 12, 0.404);
  font-family: Norwester;
  border-radius: 25px;
  color: white;
  margin: 10px;
  padding: 10px;
  width: 100%;
}

.dev-info h1 {
  margin-top: 10px;
  font-size: 2.5vw;
  text-align: center;
}

.dev-info p {
  margin-top: 10px;
  font-size: 1.5vw;
  text-align: center;
}

.dev-info a {
  text-decoration: none;
  color: white;
  flex-direction: row;
}

.dev-info i {
  font-size: 2vw;
  margin-top: 10px;
  flex-direction: row;
}

.dev-icon-container {
  display: flex;
  flex-direction: row;
}

.dev-icon {
  display: flex;
  flex-direction: row;
  margin: 5px;
}

.dev-icon h4 {
  margin: 5px;
}

.dev-pic {
  max-width: 100%;
  max-height: 30vh;
  border: 3px solid #CF5609;
  border-radius: 1em;
  justify-content: center;
  align-items: center;
  margin: 10px 0 10px 0;
}

@media screen and (max-width: 856px) {
  .dev-info {
    /* flex-direction: column; */
    overflow: hidden;
    width: 85%;
    height: 85%;
  }
  .dev-info h1 {
    margin-top: 10px;
    font-size: 6.5vw;
  }
  .dev-info p {
    margin-top: 10px;
    font-size: 3.5vw;
  }
  .dev-info i {
    font-size: 6vw;
    margin-top: 10px;
  }
}
.datacard-info {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  background-color: rgba(12, 12, 12, 0.404);
  font-family: Norwester;
  border-radius: 25px;
  color: white;
  margin: 1vw;
  padding: 1vw;
  width: 100%;
  height: 55vw;
}
.datacard-info h1 {
  margin-top: 1vw;
  font-size: 2.5vw;
  height: 9vw;
  text-align: center;
}
.datacard-info p {
  margin-top: 2vw;
  font-size: 1.5vw;
}
.datacard-info a {
  text-decoration: none;
  color: white;
}

.datacard-pic {
  width: 95%;
  border: 3px solid #CF5609;
  border-radius: 1em;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 856px) {
  .datacard-info {
    border-radius: 25px;
    margin: 2vw;
    padding: 4vw;
    width: 85%;
    height: 100%;
  }
  .datacard-info h1 {
    margin-top: 10px;
    font-size: 6.5vw;
    height: auto;
  }
  .datacard-info p {
    margin-top: 10px;
    font-size: 3.5vw;
  }
}
.contact-form-container {
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  min-height: 70vh;
}

.contact-form {
  max-width: 85vw;
  min-height: 50vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  background-color: rgba(12, 12, 12, 0.404);
  border: 3px solid white;
  font-family: Norwester;
  border-radius: 25px;
  color: white;
  margin: 2vw 0 2vw 0;
  padding: 2vw 0 2vw 0;
  width: 100%;
}

label {
  color: white;
  font-size: 2vw;
  margin: 0.6vw 0vw 0.2vw 5vw;
}

.contact-form-name,
.contact-form-email,
.contact-form-subject,
.contact-form-message {
  background-color: rgba(255, 255, 255, 0.15);
  border: 3px solid #CF5609;
  border-radius: 1rem;
  color: white;
  font-size: 1.5vw;
  margin: 0.5vw auto 1vw auto;
  padding: 1vw 1.3vw 1vw 1.3vw;
  min-width: 85%;
}

.contact-form-message {
  min-height: 20vw;
}

.contact-form-name:hover,
.contact-form-name:focus,
.contact-form-email:hover,
.contact-form-email:focus,
.contact-form-subject:hover,
.contact-form-subject:focus,
.contact-form-message:hover,
.contact-form-message:focus {
  color: white;
  border: 3px solid white;
  background-color: transparent;
}

.contact-form-submit {
  font-family: Norwester;
  background-color: rgba(255, 255, 255, 0.15);
  color: white;
  border: 3px solid #CF5609;
  border-radius: 1rem;
  font-size: 2.5vw;
  padding: 1.3vw;
}

.contact-form-submit:hover,
.contact-form-submit:focus {
  background-color: transparent;
  color: white;
  border: 3px solid white;
}

#contact-form-send {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 5vw auto 1vw auto;
}

.contact-form-result {
  margin: auto;
}

@media screen and (max-width: 856px) {
  .contact-form-container {
    min-height: 52vh;
  }
  .contact-form {
    height: 106vw;
    max-width: 90vw;
    border-radius: 25px;
    margin: 5.5vw 0 5.5vw 0;
    padding: 5vw 0 5vw 0;
    width: 100%;
  }
  label {
    color: white;
    font-size: 8vw;
    margin: 0.6vw 0vw 0.2vw 5vw;
  }
  .contact-form-name,
.contact-form-email,
.contact-form-subject,
.contact-form-message {
    background-color: rgba(255, 255, 255, 0.15);
    border: 3px solid #CF5609;
    border-radius: 1rem;
    color: white;
    font-size: 6.5vw;
    margin: 0.5vw auto 1vw auto;
    padding: 1vw 1.3vw 1vw 1.3vw;
    min-width: 85%;
    max-width: 94%;
  }
  .contact-form-message {
    min-height: 30vw;
  }
  #contact-form-send {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 5vw auto 2vw auto;
  }
  .contact-form-submit {
    font-family: Norwester;
    border-radius: 1rem;
    font-size: 9.5vw;
    padding: 1.5vw;
    margin: 1.5vw;
  }
}
/* home pg */
.home-top-banner {
  border: none;
  width: 100%;
  min-height: 70vh;
  position: relative;
  /* background-image: inherit; */
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: cover;
  background-color: rgb(105, 103, 103);
  background-image: url("/src/assets/img/homepage/2.png");
}

.cont-profile-pic {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 0vw;
  padding-top: 5vw;
}

.profile-pic {
  width: 25vw;
  border-radius: 50%;
  box-shadow: 5px 5px 10px rgb(37, 37, 37);
}

.about-text-home {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  justify-items: center;
  text-align: center;
  margin: 1vw 1vw 0vw 1vw;
  padding: 2vw;
  font-size: 2.5vw;
}

.home-portfolio-btn {
  font-family: Norwester;
  background-color: rgba(255, 255, 255, 0.15);
  color: white;
  border: 3px solid #CF5609;
  border-radius: 1rem;
  padding: 1.3vw;
}
.home-portfolio-btn a {
  text-decoration: none;
  color: white;
  font-size: 5vw;
}

@media (hover: hover) {
  .home-portfolio-btn:hover {
    border: 3px solid white;
    color: rgb(255, 255, 255);
    box-shadow: 1px 1px 20px #CF5609;
  }
  .home-portfolio-btn:hover a {
    color: #CF5609;
  }
}
.dividers-line {
  border-top: 5px solid white;
}

.icon-header {
  margin: 28px 5px 8px 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 4vw;
}

.data-text-header-home,
.developer-text-header-home,
.designer-text-header-home {
  color: #CF5609;
  font-family: Norwester;
  font-size: 8vw;
  text-align: center;
  margin: 5vw 0 0vw 0;
}

.data-logos-container {
  font-family: Norwester;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  justify-items: center;
  align-items: center;
  justify-content: center;
  margin-bottom: 2vw;
}

.code-logos-container,
.design-logos-container {
  font-family: Norwester;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  justify-items: center;
  align-items: center;
  justify-content: center;
  margin-bottom: 2vw;
}

.code-logos {
  justify-items: center;
  align-items: center;
  text-align: center;
  font-size: 2.5vw;
}
.code-logos img {
  max-width: 100%;
  height: auto;
  min-width: 30px;
}
.code-logos h3 {
  margin-top: -2.5vw;
}

.email-home {
  font-family: Norwester;
  color: #CF5609;
  margin: 1vw 0 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  justify-items: center;
  text-align: center;
  font-size: 5vw;
}

.email {
  font-family: Norwester;
  margin: 1vw 0 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  justify-items: center;
  text-align: center;
  font-size: 5vw;
}

@media screen and (max-width: 856px) {
  .home-top-banner {
    background-position: bottom;
  }
  .cont-profile-pic {
    margin-top: 0;
    padding-top: 20vw;
  }
  .profile-pic {
    width: 60vw;
  }
  .about-text-home {
    padding: 0 2vw 5vw 2vw;
    font-size: 5vw;
    margin: 3vw 1vw 0vw 1vw;
  }
  .home-portfolio-btn {
    border: 3px solid #CF5609;
    border-radius: 0.5rem;
    padding: 3vw;
  }
  .home-portfolio-btn a {
    font-size: 9vw;
  }
  .data-text-header-home,
.developer-text-header-home,
.designer-text-header-home {
    color: #CF5609;
    font-family: Norwester;
    font-size: 15vw;
    text-align: center;
    margin: 5vw 0 0vw 0;
  }
  .code-logos {
    font-size: 6.5vw;
  }
  .code-logos img {
    height: auto;
    width: 30vw;
  }
  .code-logos h3 {
    margin-top: -3.5vw;
    font-size: 6vw;
  }
  .icon-header {
    padding: 0 1vw 0 1vw;
    font-size: 6.5vw;
  }
  .code-logos-container,
.design-logos-container {
    grid-template-columns: 1fr 1fr;
  }
  .gsap {
    display: none;
  }
  .email-home {
    margin: 1vw 0 0 0;
    font-size: 6vw;
  }
  .email {
    font-size: 6vw;
  }
}
/* Developer */
.data-text-container,
.developer-text-container,
.designer-text-container {
  color: white;
}

.data-text-header,
.developer-text-header,
.designer-text-header {
  color: white;
  font-family: Norwester;
  font-size: 8vw;
  text-align: center;
  margin: 5vw 0 0vw 0;
}

.data-text-paragraph .developer-text-paragraph,
.designer-text-paragraph {
  color: white;
}

.data-portfolio-container-row-1 {
  display: flex;
  flex-direction: row;
  margin-top: 50px;
  margin-bottom: 50px;
  overflow: hidden;
  align-content: center;
  justify-content: center;
  align-items: center;
}

.portfolio-container-row-1 {
  display: flex;
  flex-direction: row;
  margin-top: 50px;
  margin-bottom: 50px;
  overflow: hidden;
}

.portfolio-container-row-2 {
  display: flex;
  flex-direction: row;
  position: relative;
  margin-top: 50px;
  margin-bottom: 50px;
  overflow: hidden;
}

.portfolio-container-row-3 {
  display: flex;
  flex-direction: row;
  position: relative;
  margin-top: 50px;
  margin-bottom: 50px;
  overflow: hidden;
}

@media screen and (max-width: 856px) {
  .data-text-container,
.developer-text-container,
.designer-text-container {
    color: white;
  }
  .data-text-header,
.developer-text-header,
.designer-text-header {
    font-size: 15vw;
    margin: 15vw 0 2vw 0;
  }
  .portfolio-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 2px;
  }
  .data-portfolio-container-row-1 {
    flex-direction: column;
    align-content: center;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 1px;
  }
  .portfolio-container-row-1,
.portfolio-container-row-2,
.portfolio-container-row-3 {
    flex-direction: column;
    align-content: center;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 1px;
  }
}
/* CSS grid */
.gd-gallery {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  /* grid-template-columns: 1fr 1fr 1fr 1fr; */
  /* grid-template-columns: minmax(400px, 1fr); */
  /* grid-template-columns: repeat(auto-fit, minmax(400px, 1fr)); */
  /* grid-template-rows: repeat(5, 400px); */
  grid-gap: 15px;
  overflow: hidden;
  justify-content: center;
  justify-items: center;
  align-items: center;
  object-fit: cover;
  margin: 2vw 1vw 0 1vw;
}

.gd-gallery-img {
  max-width: 100%;
  height: auto;
  min-width: 30px;
}

@media screen and (max-width: 856px) {
  .gd-gallery {
    grid-template-columns: minmax(300px, 1fr);
    margin: 8vw;
  }
}
/* about pg */
.about-text {
  display: flex;
  justify-content: center;
  justify-items: center;
  align-items: center;
  align-content: center;
  text-align: center;
  margin: 2.5vw 0 0 0;
  padding: 0 1vw 0 1vw;
  font-size: 3vw;
}

.resume {
  background-color: #CF5609;
  color: white;
  font-family: digital-7;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2vw 0 0 0;
  font-size: 4.5vw;
}

.secret-message {
  font-family: digital-7;
  display: flex;
  color: white;
  text-align: center;
  text-decoration: none;
  justify-content: center;
  align-items: center;
  font-size: 2.5vw;
  margin: 1vw 0 0.5vw 0;
  padding: 0 1vw 0 1vw;
}

@media screen and (max-width: 856px) {
  /* about pg */
  .about-text {
    font-size: 6.5vw;
    padding: 0 2vw 0 2vw;
  }
  .resume {
    font-size: 9.5vw;
  }
  .secret-message {
    font-size: 5.5vw;
    margin: 1vw 0 0.5vw 0;
    padding: 0 1vw 0 1vw;
  }
}
.contact-text-container {
  color: white;
}

.contact-text-header {
  color: white;
  font-family: Norwester;
  font-size: 9vw;
  text-align: center;
  margin: 5vw 0 4vw 0;
}

.contact-text-paragraph {
  color: white;
  font-size: 5vw;
  text-align: center;
}

@media screen and (max-width: 856px) {
  .contact-text-header {
    font-size: 15vw;
    margin: 18vw 0px 4vw 0;
  }
  .contact-text-paragraph {
    font-size: 10vw;
  }
}
.project-title {
  font-size: 3.5vw;
  text-align: center;
}

.project-pic-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 5vw 0 0 0;
}

.data1-pic {
  width: 95vw;
  box-shadow: 5px 5px 10px rgb(37, 37, 37);
}

.project-disclaimer {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  justify-items: center;
  align-items: center;
  align-content: center;
  text-align: center;
  margin: 2.5vw 0 0 0;
  padding: 0 2vw 0 2vw;
  font-size: 1.5vw;
}

.project-description {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  justify-items: center;
  align-items: center;
  align-content: center;
  text-align: justify;
  margin: 2.5vw 3vw 0 3vw;
  padding: 0 1vw 0 1vw;
  font-size: 3vw;
}

.project-description-list {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  justify-items: center;
  align-items: flex-start;
  align-content: center;
  text-align: justify;
  margin: 2.5vw 3vw 0 6vw;
  padding: 0 1vw 0 1vw;
  font-size: 3vw;
}

.github-icon-container {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  align-items: center;
}

.github-large-icon {
  text-decoration: none;
  margin: 5vw 0 3vw 0;
}
.github-large-icon a {
  text-decoration: none;
  color: white;
  font-size: 10vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;
}
.github-large-icon h4 {
  text-decoration: none;
  text-align: center;
  margin: 5px;
  font-size: 5vw;
}

.dev-icon-container {
  display: flex;
  flex-direction: row;
}

.back-portfolio-btn {
  font-family: Norwester;
  background-color: rgba(255, 255, 255, 0.15);
  color: white;
  border: 3px solid #CF5609;
  border-radius: 1rem;
  margin: 4vw 0 0 0;
  padding: 1.3vw;
}
.back-portfolio-btn a {
  text-decoration: none;
  color: white;
  font-size: 5vw;
}

@media screen and (max-width: 856px) {
  .project-title {
    font-size: 4.5vw;
  }
  .project-pic-container {
    margin-top: 5vw;
  }
  .data1-pic {
    width: 95vw;
    box-shadow: 5px 5px 10px rgb(37, 37, 37);
  }
  .project-disclaimer {
    margin: 2vw 0 0 0;
    padding: 0 1vw 0 1vw;
    font-size: 3.5vw;
  }
  .project-description {
    margin: 2.5vw 2vw 0 2vw;
    padding: 0 2vw 0 2vw;
    font-size: 4vw;
  }
  .project-description-list {
    margin: 2.5vw 2vw 0 6vw;
    padding: 0 2vw 0 2vw;
    font-size: 4vw;
  }
  .github-large-icon {
    margin: 5vw 1vw 3vw 1vw;
  }
  .github-large-icon a {
    font-size: 15vw;
  }
  .github-large-icon h4 {
    margin: 5px;
    font-size: 5vw;
  }
  .back-portfolio-btn {
    margin: 6vw 0 0 0;
    border: 3px solid #CF5609;
    border-radius: 0.5rem;
    padding: 3vw;
  }
  .back-portfolio-btn a {
    font-size: 6vw;
  }
}