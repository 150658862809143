/* Developer */
.data-text-container,
.developer-text-container,
.designer-text-container {
    color: white;
  }
  
  .data-text-header,
  .developer-text-header,
  .designer-text-header {
    color: white;
    font-family: Norwester;
    font-size: 8vw;
    text-align: center;
    margin: 5vw 0 0vw 0;
  }
  
  .data-text-paragraph
  .developer-text-paragraph,
  .designer-text-paragraph {
    color: white;
   //  font-size: 5vw;
  }

  .data-portfolio-container-row-1 {
   display: flex;
   flex-direction:row;
   margin-top: 50px;
   margin-bottom: 50px;
   overflow: hidden; 
   align-content: center;
   justify-content: center;
   align-items: center;
 }
  
  .portfolio-container-row-1 {
    display: flex;
    flex-direction:row;
    margin-top: 50px;
    margin-bottom: 50px;
    overflow: hidden; 
  }
  
  .portfolio-container-row-2 {
    display: flex;
    flex-direction:row;
    position: relative;
    margin-top: 50px;
    margin-bottom: 50px;
    overflow: hidden; 
  }

  .portfolio-container-row-3 {
   display: flex;
   flex-direction:row;
   position: relative;
   margin-top: 50px;
   margin-bottom: 50px;
   overflow: hidden; 
 }
  
  @media screen and (max-width: 856px) {
    
   .data-text-container,
   .developer-text-container,
   .designer-text-container{
      color: white;
   }
  
    .data-text-header,
    .developer-text-header,
    .designer-text-header {
      font-size: 15vw;
      margin: 15vw 0 2vw 0;
    }

   //  .data-text-paragraph,
   //  .developer-text-paragraph,
   //  .designer-text-paragraph {
   //    font-size: 10vw;
   //  }
    .portfolio-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      align-content: center;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 2px;
    }

    .data-portfolio-container-row-1 {
      flex-direction: column;
      align-content: center;
      align-items: center;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 1px;
    }
  
    .portfolio-container-row-1,
    .portfolio-container-row-2,
    .portfolio-container-row-3 {
      flex-direction: column;
      align-content: center;
      align-items: center;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 1px;
    }
  }