/* navbar */
.desktop-navigation-home {
   position: relative;
   display: flex;
   flex-direction: row;
   align-items: center;
   justify-content: center;
   align-content: center;
   z-index: 1;
   bottom: 0;
   padding: 2px;
   margin-top: 25vh;
   height: 3vw;
   border-bottom: solid $orange;
   border-width: .5vw;
   //  background-color: rgba(12, 12, 12, 0.404)
  }
  
.links-home {
    font-family: Norwester;
    float: right;
    padding: 0;
    margin: 0;
    width: 80%;
    height: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;

    li {
        list-style: none;
    }
    
    a {
        display: inline-block;
        margin: 0 2px 0 2px;
        font-size: 2.2vw;
        font-weight: bold;
        color: #fff;
        text-decoration: none;
      //   text-shadow: 0px 0px 8px rgb(114, 111, 111), 0px 0px 25px rgb(114, 111, 111);
    }
}



#active {
    color: $orange;
}

.mobile-links-home {
    display: none;
}

.icon-burger-home {
    display: none;
    position: absolute;
    right: 5%;
    top: 50%;
    transform: translateY(-50%);
}
.icon-burger-home .line {
    width: 30px;
    height: 5px;
    background-color: #fff;
    margin: 5px;
    border-radius: 3px;
    transition: all .3s ease-in-out;
}

@media screen and (max-width: 856px) {

    /* navbar */
    .desktop-navigation-home {
        display: none;
    }

    .icon-burger-home {
        display: block;
        z-index: 2;
        position: relative;
        float: right;
        height: 0vh;
      //   margin-top: 4vw;
      //   min-height: 55vw;
        
        .line {
            background-color: $orange;
        }
    }
    
  
    .mobile-links-home {
        display: flex;
        font-family: Norwester;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        position: fixed;
        z-index: 1;
        left: 0;
        right: 0;
        top: 2.5vw;
        bottom: 0;
        width: auto;
        height: auto;
        background-color: rgba(0,0,0,.8);
        overflow: hidden;
        box-sizing: border-box;
        padding: 0;
        transition: all .5s ease-in-out;

        li {
            list-style: none;
        }

        a {
            text-decoration: none;
            color: white;
            font-size: 7vw;
            // text-shadow: 0px 0px 8px rgb(114, 111, 111), 0px 0px 25px rgb(114, 111, 111);
        }
    }

}